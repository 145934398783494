<template>
  <div class="signup-done" v-if="!isProcessing">
    <div class="signup-done__complete">
      <v-icon class="signup-done__complete__icon">check_circle_outline</v-icon>
      <template v-if="this.isSetGroupSetting">
        <p class="signup-done__complete__text">
          限定グループ「{{ this.groupName }}」への参加申請ありがとうございます。
        </p>
        <p class="signup-done__complete__text--warning">承認まで3営業日ほどお待ちください。</p>
      </template>
      <template v-else>
        <p class="signup-done__complete__text">
          ご登録いただき誠にありがとうございます。 <br />
          引き続き「{{ this.serviceName }}」をよろしくお願いします。
        </p>
      </template>
      <v-btn class="signup-done__complete__btn" depressed @click="$router.push({ name: 'home' })">ホームへ移動する</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'signup_done',
  async mounted () {
    const promises = []

    // サービス名が未取得なら取得する
    if (!this.serviceName) promises.push(this.$store.dispatch('settingGeneral/getSettingGeneral', 'serviceName'))

    // 限定グループ名が未取得なら取得
    if (!this.groupName) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'groupName'))

    // 限定グループURLが未取得なら取得
    if (!this.groupURL) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'groupURL'))

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    serviceName () {
      return this.$store.getters['settingGeneral/settingGeneral']('serviceName').value
    },
    /**
     * @return {Boolean} 限定グループ設定の状態
     */
    isSetGroupSetting () {
      return this.$store.getters['settingGroup/isSetGroupSetting']
    },
    /**
     * @return {String} 限定グループの名前
     */
    groupName () {
      return this.$store.getters['settingGroup/groupName']
    },
    /**
     * @return {String} 限定グループのURL
     */
    groupURL () {
      return this.$store.getters['settingGroup/groupURL']
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.signup-done {
  display: flex;
  align-items: center;
  width: 100vw;
  max-width: $max_width;
  min-height: 100vh;
  text-align: center;
  background-color: $green_color;
  &__complete {
    width: 100%;
    padding: $padding_height $padding_width;
    &__icon {
      font-size: 20rem;
      color: $white_color;

      @media (max-width: $max_width) {
        font-size: calc(100vw * 0.488);
      }
    }
    &__text {
      margin: 60px 0 0;
      font-size: 1.4rem;
      font-weight: normal;
      color: $white_color;
      text-align: center;
      word-wrap: break-word;
      &--warning {
        @extend .signup-done__complete__text;
        margin-top: 60px;
      }
    }
    &__btn {
      display: block;
      margin: 60px auto 0;
      font-size: 1.2rem;
      font-weight: bold;
      color: $green_color;
      border-radius: 15px;
      &.v-btn:not(.v-btn--round).v-size--default {
        width: auto;
        height: auto;
        padding: 15px 20px;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $white_color;
      }
    }
  }
}
</style>
